import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Grid, Input } from 'semantic-ui-react'

export const Search = ({ isLoading, getFiles, filter, setFilter, touched, setTouched, amount = 0 }) => {

    const history = useHistory()
    const timeoutRef = useRef(null)

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null
            if (touched) {
                setTouched(false)
            }
        }, 5000)
    }, [touched, setTouched, filter])

    async function handeKeydown(e) {
        if (e.key === 'Enter') {
            if (filter?.length < 5) return
            setTouched(false)

            await getFiles(filter)
            history.push(filter)
        }
        if (e.key === 'Escape') {
            setFilter('')
        }
    }

    function handleChanged(e) {
        setFilter(e.target.value)
        setTouched(true)
    }

    return (
        <Container style={{ padding: '1.5rem', marginTop: '5rem' }} textAlign="center">
            <Grid columns={1}>
                <Grid.Column>
                    <Grid.Row>
                        <Input
                            style={{ minWidth: '300px' }}
                            size='large'
                            value={filter}
                            loading={isLoading}
                            icon="search"
                            placeholder="Etsi tunniste..."
                            onKeyDown={handeKeydown}
                            onChange={handleChanged}

                        />
                    </Grid.Row>

                    <Grid.Row>
                        <p style={{ color: '#646464' }}>
                            {(touched && filter) ?
                                `Hae tunnisteella "${filter}" (min - 5 merkkiä) painamalla 'Enter' tai tyhjennä - 'Esc'`
                                : `hakutulos: ${amount}`}

                        </p>
                    </Grid.Row>

                </Grid.Column>
            </Grid>
        </Container>
    )
}