import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

export const Range = ({ setRange }) => {

    const onChange = (event, data) => {
        setRange(data.value)
    }

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <SemanticDatepicker locale="fi-FI" onChange={onChange} type="range" />
            <p style={{ color: '#646464' }}>
                Rajaa päivämäärän mukaan
            </p>
        </div>
    )
}
