import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import { Main} from './components/Main'

Amplify.configure(awsConfig);


function App() {

    return (
        <Router>
            <Main />
        </Router>
    );
}

export default App