import React, { useState, useEffect } from 'react';
import { Switch, useLocation, Route } from 'react-router-dom'
import MainHeader from './MainHeader';
import 'semantic-ui-css/semantic.min.css';
import { Card, Container } from 'semantic-ui-react';
import { Storage } from 'aws-amplify';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { ImagesAll } from './ImagesAll'
import { Selection } from './Selection'
import { Range } from './Range'
import { Search } from './Search'
import { NoResults } from './NoResults';

function getDate(filename) {
    const imgFileNameSplitted = filename.split('_')
    if (imgFileNameSplitted.length > 2) {
        return imgFileNameSplitted[2].split('.').shift().substring(0,8)
    } 
    return new Date().toISOString('T').split('T')[0].replaceAll('-', '') 
}


export function Main() {

    const location = useLocation();

    const [firstload, setFirstload] = useState(false)
    const [images, setImages] = useState([])
    const [showByCategory, setShowbyCategory] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState(location.pathname.slice(1))
    const [touched, setTouched] = useState(false)
    const [range, setRange] = useState([])
    const [isViewerOpen, setIsViewerOpen] = useState(false)

    async function getFiles(filter = '') {
        if (filter === '' || filter?.length < 5) {
            setFirstload(false)
            return
        }
        setIsLoading(true)
        try {
            const result = await Storage.list(filter, { level: 'public' })
            const _images = []
            for (let i = 0; i < result.length; i++) {
                let output = {};
                let signedUrl = await Storage.get(result[i].key, { expires: 9000 });
                if (result[i]?.key) {

                    output.src = signedUrl
                    output.imageKey = result[i].key
                    output.added = moment(getDate(result[i].key)).toDate()
                    output.dateNormalized = output.added.toLocaleDateString('fi-FI');
                }
                if (output?.imageKey.split('/').shift() === filter) {
                    Object.keys(output).length > 0 && _images.push(output);
                }
            }

            setImages(_images);
            setIsLoading(false)
            setFirstload(false)

        } catch (error) {
            setIsLoading(false)
            setFirstload(false)
        }
    }

    useEffect(() => {
        setFirstload(true)
        getFiles(location.pathname.slice(1))

    }, [location])

    function setIsViewerOpenToFalse() {
        setIsViewerOpen(false)
    }
    function setIsViewerOpenToTrue() {
        setIsViewerOpen(true)
    }

    return (
        <Switch>
            <LoadingOverlay active={firstload} spinner text="Ladataan kuvat">
                <Container style={{ minHeight: '100vh' }} fluid>
                    <MainHeader />
                    <Search
                        isLoading={isLoading}
                        getFiles={getFiles}
                        filter={filter}
                        setFilter={setFilter}
                        touched={touched}
                        setTouched={setTouched}
                        amount={images?.length}
                    />
                    <Route path="/:id">
                        {!isViewerOpen && <Selection
                            images={images}
                            setImages={setImages}
                            showByCategory={showByCategory}
                            setShowbyCategory={setShowbyCategory}
                        />}
                        {images.length > 0 &&
                            <Range
                                setRange={setRange}
                                showResults={!firstload && !isLoading && filter?.length > 0}
                                amount={images?.length}
                            />
                        }
                        <NoResults
                            images={images}
                            isLoading={isLoading}
                            filter={filter}
                            touched={touched}
                        />
                        <ImagesAll
                            images={images}
                            range={range}
                            isViewerOpen={isViewerOpen}
                            setIsViewerOpenToFalse={setIsViewerOpenToFalse}
                            setIsViewerOpenToTrue={setIsViewerOpenToTrue}

                        />


                    </Route>
                    <Route exact path="/">
                        <Card centered>
                            <Card.Content header="Ohje" />
                            <Card.Content description="Syötä sähkökaapin tunniste yllä olevaan hakukenttään tai selaimen osoiteriviin" />
                        </Card>
                    </Route>
                </Container>
            </LoadingOverlay>
        </Switch>

    );
}
