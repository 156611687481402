import React, { useState } from 'react';
import { Container, Grid, Radio } from 'semantic-ui-react'



export const Selection = ({ images, setImages  }) => {
    const [orderDate, setOrderDate] = useState(false);
    const [orderById, setOrderById] = useState(false);

    function handleDateOrderChange() {
        const _images = [...images]
        _images.sort((a, b) => {
            if (a?.added > b?.added) {
                return orderDate ? -1 : 1
            }
            if (a?.added === b?.added) {
                return 0
            }
            return orderDate ? 1 : -1
        })
        setImages(_images)
        setOrderDate(!orderDate)
    }
    function handleOrderById() {
        const _images = [...images]
        _images.sort((a, b) => {
            if (a?.imageKey > b?.imageKey) {
                return orderById ? -1 : 1
            }
            if (a?.imageKey === b?.imageKey) {
                return 0
            }
            return orderById ? 1 : -1
        })

        setImages(_images)
        setOrderById(!orderById)
    }

    return (
        <Container style={{display: images.length > 0 ? 'block' : 'none'}} textAlign="center">
            <Grid columns={2} padded="vertically">
                <Grid.Column>
                    <Radio
                        slider
                        label={orderDate ? 'PVM nouseva järjestys' : 'PVM laskeva järjestys'}
                        onChange={handleDateOrderChange}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Radio
                        slider
                        label={orderById ? 'tunniste nouseva järjestys' : 'tunniste laskeva järjestys'}
                        onChange={handleOrderById}
                    />
                </Grid.Column>

            </Grid>
        </Container>
    )
}
