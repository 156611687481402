import { Menu } from 'semantic-ui-react'
import eleniaLogo from '../images/elenia-logo.svg'

function MainHeader() {
    return (
        <Menu borderless>
            <Menu.Item>
                <img style={{ minWidth: '140px' }} alt="elenia logo" src={eleniaLogo} />
            </Menu.Item>
            <Menu.Item header as="h3" content="EleniaGO -kuvapalvelu" />
        </Menu>
    )
}

export default MainHeader;