import React from 'react';
import { Message } from 'semantic-ui-react';


export const NoResults = ({ images, isLoading, filter, touched }) => {
    if (images.length === 0 && !isLoading && filter.length > 0 && !touched)
        return <Message
            style={{ maxWidth: '400px', margin: '0 auto' }}
            info
            header={`Hakutulos - 0 `}
            content={`Tunnisteelle ID: "${filter}" ei ole valokuvia`} />
    return null
}
