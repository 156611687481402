import React, { useState, useCallback } from 'react';
import { Image } from 'semantic-ui-react';
import SimpleImageViewer from 'react-simple-image-viewer'


export const ImagesAll = ({ images, range, isViewerOpen, setIsViewerOpenToFalse, setIsViewerOpenToTrue }) => {
    const [currentImage, setCurrentImage] = useState(0)

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index)
        setIsViewerOpenToTrue()
    }, [setIsViewerOpenToTrue])

    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpenToFalse()
    }

    let _images
    let _imagesUrls
    if (range?.length === 1) {
        _images = images.filter(img => img.added.toDateString() === range[0].toDateString())
        _imagesUrls = _images.map(img => img.src)
    }
    else if (range?.length === 2) {
        _images = images.filter(img => img.added >= range[0] && img.added <= range[1])
        _imagesUrls = _images.map(img => img.src)
    } else {
        _images = images
        _imagesUrls = _images.map(img => img.src)
    }

    return (
        <div>
            <Image.Group style={{ padding: '20px' }} size='tiny' >
                {_images.map((img, index) => (
                    <Image
                        label={img.dateNormalized}
                        key={img.src}
                        src={img.src}
                        onClick={() => openImageViewer(index)}
                        style={{ cursor: 'pointer' }} />
                ))}
            </Image.Group>
            {isViewerOpen && (
                <SimpleImageViewer
                    src={_imagesUrls}

                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </div>
    )
}
